export class User {
  id: string;
  name: string;
  email: string;
  password?: string;
  permissions: string[];
  is_active: boolean;
  is_blocked: boolean;
  telegram_chat_id: number;
  viber_chat_id: string;

  constructor(props: { id: string; name: string; email: string; password?: string; permissions: string[]; is_active: boolean; is_blocked: boolean; telegram_chat_id?: number; viber_chat_id?: string }) {
    this.id = props.id;
    this.name = props.name;
    this.email = props.email;
    this.password = props.password;
    this.permissions = props.permissions;
    this.is_active = props.is_active;
    this.is_blocked = props.is_blocked;
    this.telegram_chat_id = props?.telegram_chat_id ?? 0;
    this.viber_chat_id = props?.viber_chat_id ?? "";
  }
}
