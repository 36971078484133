import { SuccessResponse } from "@/types/response";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { ErrorResponse } from "@/types/response";

const instance: AxiosInstance = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

const onResponse = (response: AxiosResponse<SuccessResponse>): AxiosResponse<SuccessResponse> => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<ErrorResponse> => {
  return Promise.reject(error.response?.data);
};

instance.interceptors.response.use(onResponse, onResponseError);

export function withRecaptchaHeader(token: string) {
  const headers = Object.assign({}, { "x-recaptcha-token": token });
  return { headers };
}

export default instance;
